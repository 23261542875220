<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs"/>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>{{ titleText }}</template>
              <template v-slot:body>
                <div class="form">
                  <FormRow :required="true">
                    <template v-slot:label>テンプレート名</template>
                    <template v-slot:labelNote>（20文字以内）</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <input
                          :class="{ 'form-control': true, 'is-error': errors.name }"
                          type="text"
                          name="name"
                          maxlength="20"
                          v-trim
                          v-maxlength
                          v-model="form.name"
                        />
                      </div>
                    </template>
                  </FormRow>
                  <FormRow :required="true">
                    <template v-slot:label>ニュースタイトル</template>
                    <template v-slot:labelNote>（20文字以内）</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <input
                          :class="{ 'form-control': true, 'is-error': errors.title }"
                          type="text"
                          name="title"
                          maxlength="20"
                          v-trim
                          v-maxlength
                          v-model="form.title"
                        />
                      </div>
                    </template>
                  </FormRow>
                  <FormRow :required="true">
                    <template v-slot:label>本文</template>
                    <template v-slot:labelNote>（2000文字以内）</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <textarea
                          :class="{ 'form-control form-textarea': true, 'is-error': errors.content }"
                          type="text"
                          name="content"
                          maxlength="2000"
                          v-trim
                          v-maxlength
                          v-model="form.content"
                        ></textarea>
                      </div>
                    </template>
                  </FormRow>
                  <FormRow>
                    <template v-slot:label>画像添付</template>
                    <template v-slot:labelNote>（jpg形式・1ファイル3MBまで）</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <ul class="fileUpload">
                          <li class="fileUpload-item" v-for="(file, index) in loadingFiles" :key="index">
                            <Fileupload
                              :maxSize="3"
                              @uploaded="setFileUpload(index, ...arguments)"
                              @removed="removeFileUpload"
                              :loading="loadingFiles[index]"
                              :initialUrl="initialImages[index]"
                            />
                          </li>
                        </ul>
                      </div>
                      <div class="form-content-row">
                        <p>※推奨サイズ：750ｘ500px</p>
                      </div>
                    </template>
                  </FormRow>
                  <FormRow>
                    <template v-slot:label>プッシュ通知タイトル</template>
                    <template v-slot:labelNote>（40文字以内）</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <input
                          :class="{ 'form-control': true, 'is-error': errors.pushMessage }"
                          type="text"
                          name="pushMessage"
                          maxlength="40"
                          v-trim
                          v-maxlength
                          v-model="form.pushMessage"
                        />
                      </div>
                    </template>
                  </FormRow>
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item pos-end">
            <ul class="listGrid">
              <li class="listGrid-item">
                <router-link class="btn btn-white" :to="{ name: 'EventUserTemplates' }">キャンセル</router-link>
              </li>
              <li class="listGrid-item">
                <ActionButton class="btn btn-main" :handle-submit="register" :button-text="buttonText" />
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
//mixins
import nav from '@/mixins/nav/user';
import error from '@/mixins/plugin/error';
//components
import FormRow from '@/components/FormRow.vue';
import Fileupload from '@/components/Fileupload.vue';

export default {
  data: function() {
    return {
      pageName: '顧客管理',
      storeModule: "customer",
      form: {
        name: '',
        title: '',
        content: '',
        image: [],
        pushMessage: ''
      },
      initialImages: [],
      loadingFiles: [false, false, false, false],
    };
  },
  mixins: [nav, error],
  components: {
    FormRow,
    Fileupload,
  },
  validations() {
    const form = {
      name: { required, maxLength: maxLength(20) },
      title: { required, maxLength: maxLength(20) },
      content: { required, maxLength: maxLength(2000) },
      pushMessage: { maxLength: maxLength(40) },
    };
    return { form };
  },
  computed: {
    ...mapGetters({
      userInfo: 'auth/infor',
      newsDetail: 'customer/newsDetail',
    }),
    newsId() {
      return this.$route.params.id;
    },
    subdomain() {
      return this.$route.params.subdomain;
    },
    titleText() {
      return !this.newsId ? 'ニュース登録' : 'ニュース編集';
    },
    buttonText() {
      return !this.newsId ? '登録する' : '編集する';
    }
  },
  async mounted() {
    const loading = this.$loading.show();
    this.initForm();
    Promise.all([
      this.$store.dispatch('event/getParentEventDetail', this.subdomain),
      this.getDetail(),
    ]).finally(() => {
      if (this.newsId) {
        this.form = { ...this.form, ...this.newsDetail };
        this.initialImages = (this.newsDetail?.image || []).map(item => item.url);
      } else {
        this.form = {
          ...this.form,
          image: [
            { id: null, order: 0, url: null },
            { id: null, order: 1, url: null },
            { id: null, order: 2, url: null },
            { id: null, order: 3, url: null },
          ]
        }
      }
      this.$loading.clear(loading);
    })
  },
  methods: {
    initForm() {
      this.form = {
        name: '',
        title: '',
        content: '',
        image: [],
      };
    },
    async setFileUpload(index, url) {
      this.updateLoadingFile(true, index);
      const imageId = await this.$store.dispatch('common/uploadImage', {
        image: url,
        order: index,
        isTemplate: true,
      });
      this.form.image = this.form.image.map(item => {
        if (item.order === index) return { id: imageId, url, order: index };
        return item;
      })
      this.updateLoadingFile(false, index);
    },
    updateLoadingFile(val, index) {
      const loadingArray = [...this.loadingFiles];
      loadingArray[index] = val;
      this.loadingFiles = loadingArray;
    },
    removeFileUpload(url) {
      const images = [...this.form.image];
      this.form.image = images.map(item => {
        if (item.url === url) return { ...item, id: null, url: null };
        return item;
      });
    },
    async getDetail() {
      if (this.newsId) {
        await this.$store.dispatch('customer/getNewsDetail', {
          subdomain: this.subdomain,
          id: this.newsId
        });
      }
    },
    async register() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.form, 'news');
        return false
      } else {
        let { ...payload } = this.form;
        const data = {
          ...payload,
          id: this.newsId,
          image: this.form?.image?.length ? this.form?.image?.filter(e => e.id).map(elm => elm.id) : null,
        }
        if (!this.newsId) delete data.id;
        const actionPath = !this.newsId ? 'createNews' : 'updateNews';
        const result = await this.$store.dispatch(`${this.storeModule}/${actionPath}`, data);
        if (result) {
          const actionName = this.newsId ? 'updated' : 'created';
          await this.$router.push({ name: 'EventUserTemplates' });
          this.$message[actionName]('template');
        }
      }
    }
  },
  beforeDestroy() {
    if (!this.$route.name.includes('EventUserTemplates')) sessionStorage.removeItem('tabActive')
  }
};
</script>
